<template>
  <div class="zchjs">
    <div class="header">
      <div class="h1">产业园区</div>
      <div class="h2">助力数字化产业园区发展</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item  :to="{ path: '/yqgl' }">产业园区列表</el-breadcrumb-item>
        <el-breadcrumb-item >产业园区详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="flex">
        <div class="left">
          <div>
            <el-image fit="fill"  class="image" :src="datas.image"  lazy></el-image>
          </div>
          <div class="desc">
            <div class="title">{{datas.name}}</div>
            <div>
              <span class="biaoqian">{{datas.level}}</span>
            </div>
            <div>园区面积：{{datas.area}}</div>
            <div>上云企业：{{datas.enterpriseQty}}家</div>
            <div>园区地址：{{datas.provinceName}}{{datas.cityName}}{{datas.countyName}}{{datas.address}}</div>
            <div>主导产业：{{datas.industry}}</div>
            <div>负责人：{{datas.headUser}}</div>
            <div>联系电话：{{datas.headPhone}}</div>
            <div class="jianjie">简介</div>
            <div v-html="datas.content"></div>
          </div>
        </div>
        <div class="flex-1">
          <div>
            <el-empty style="height: 600px" v-if="page.total==0" ></el-empty>
            <div class="comp" v-for="(item,index) in arr" :key="index" @click="gocopmxq(item)">
              <div  class="flex">
                <div class="compimage">
                  <el-avatar  shape="square" :size="60" src="https://admin.bhxqcy.cz-icloud.com/img/qiye.e9e33407.png">
                    <img src="https://admin.bhxqcy.cz-icloud.com/img/qiye.e9e33407.png"/>
                  </el-avatar>
                </div>
                <div class="comptitle">
                  <div>{{item.enterpriseName}}</div>
                </div>
              </div>
              <div class="compdesc">
                <el-row>
                  <el-col :span="3" ><div>法定代表人：</div></el-col>
                  <el-col :span="4"><div class="blue">{{item.legalPerson}}</div></el-col>
<!--                  <el-col :span="3" ><div>注册资本：</div></el-col>-->
<!--                  <el-col :span="4"><div class="blue">{{item.registeredCapital}}</div></el-col>-->
                </el-row>
                <el-row>
<!--                  <el-col :span="3" ><div>成立日期：</div></el-col>-->
<!--                  <el-col :span="4"><div class="blue">{{item.foundedTime}}</div></el-col>-->
                  <el-col :span="3" ><div>企业类型：</div></el-col>
                  <el-col :span="4"><div class="blue">{{item.enterpriseNatureName}}</div></el-col>
                </el-row>
                <el-row>
                  <el-col :span="3" ><div>地址：</div></el-col>
                  <el-col :span="16"><div>{{item.provinceName}}{{item.cityName}}{{item.countyName}}{{item.address}}</div></el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div class="fenye ">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :current-page="page.current"
                :page-sizes="[10, 50, 100, 200]"
                :page-size="page.size"
                :total="page.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { fiveyears } from '../../utils/index'
import {policyindex, policypage} from "../../request/moudle/zcfw";
import {enterpriselist, parklist} from "../../request/moudle/park";
export default {
  name: "zchj",
  data(){
    return {
      page:{
        current:1,
        size:10,
        total:0
      },
      arr:[],
      datas:''
    }
  },
  created() {
    this.loadxq()
    this.loadlist()
  },
  methods:{
    gocopmxq(item){
      this.$router.push({
        path:'/yqglcompxq',
        query:{
          id:item.id,
        }
      })
    },
    loadxq(bol){
      this.$api.park.parkdetail({
        id:this.$route.query.id
      }).then((res)=>{
        this.datas=res.data
      })
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      const obj={
        parkId:this.$route.query.id
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.park.enterpriselist(obj).then((res)=>{
        this.arr=res.data.records
        this.page.total=res.data.total
      })
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
  }
}
</script>

<style lang="scss" scoped>
.zchjs{
  background: #F5F5F5;
}
.blue{
  color: #008AF5;
}
.mbx{
  margin-bottom: 20px;
}
.fenye{
  text-align: center;
  padding: 30px 0;
}
.header{
  height: 220px;
  background: url("../../assets/banner/yqgl.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.content{
  width: 1200px;
  margin: 20px auto;
  .comp{
    background: #fff;
    border-radius: 4px;
    padding: 29px;
    margin-bottom: 20px;
    cursor: pointer;
    .comptitle{
      font-weight: bolder;
      margin-left: 20px;
    }
    .compdesc{
      font-size: #3E3E3E;
      text-align: left;
      line-height: 25px;
      margin-top: 10px;
    }
  }
  .left{
    width: 280px;
    margin-right: 30px;
    color: #fff;
    background: #fff;
    padding: 15px;
    .image{
      width: 280px;
      height: 200px;
    }
    .biaoqian{
      background:linear-gradient(0deg,#0078F2,#00BAFF);
      padding: 0px 8px;
      margin-right: 10px;
      border-radius: 4px;
      color: #fff;
    }
    .desc{
      color: #4C4B4B;
      div{
        padding: 5px 0;
      }
      .title{
        font-weight: bolder;
        margin-top:15px;
      }
      .jianjie{
        font-weight: bolder;
        padding: 5px 0;
        border-bottom: 1px solid #3B93F4 ;
        margin: 40px 0 20px 0;
      }
    }
  }
}
</style>